import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Med Ball Chest Pass 10×1 for distance `}<em parentName="p">{`(on turf)`}</em></p>
    <p>{`100M Rowing Sprint (legless) 5×1 w/1:00 rest between attempts.`}</p>
    <p><em parentName="p">{`Record fastest time/100M`}</em></p>
    <p>{`Bench Press 4×4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`6 Rounds for time of:`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`15-Pushups`}</p>
    <p>{`20-Situps`}</p>
    <p><em parentName="p">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here at
The Ville September 14th!  Learn more about the Throwdown here:
 `}<a parentName="em" {...{
          "href": "https://thegranitegames.com/about-throwdowns/"
        }}>{`https://thegranitegames.com/about-throwdowns/`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      